body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #e2e8f0;

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.quiz-form {
  display: flex;
  flex-direction: column;
  /* max-width: 600px; */
  margin: auto;
  /* margin: 20px auto; */
  /* padding: 20px; */
  /* border: 1px solid #ddd; */
  /* border-radius: 10px; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  background-color: #fff;
  transition: box-shadow 0.3s ease;
}

.quiz-form:hover {
  /* box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); */
}

.quiz-form h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
  font-weight: bold;
}

.quiz-form label {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  font-size: 16px;
  color: #555;
}

.quiz-form input,
.quiz-form select {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.quiz-form input:focus,
.quiz-form select:focus {
  border-color: #007bff;
}

.btn {
  padding: 10px;
  margin-top: 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #0056b3;
}

.error {
  color: #ff0000;
  background-color: #ffffff;
  font-size: 12px;
  width: fit-content;
  margin-left: 7px;
  padding: 0px 5px;
  margin-top: -10px;
  margin-bottom: 10px;
}

.fee-info {
  margin-bottom: 20px;
  font-size: 14px;
  color: #555;
}

.fee-info .bold {
  font-weight: bold;
  color: #ff0000;
}

@media (max-width: 768px) {
  .quiz-form {
    /* padding: 15px; */
  }

  .quiz-form input,
  .quiz-form select {
    padding: 8px;
  }

  .quiz-form button {
    padding: 8px;
    font-size: 14px;
  }

  .quiz-form label {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .quiz-form {
    /* padding: 10px; */
  }

  .quiz-form input,
  .quiz-form select {
    padding: 6px;
    font-size: 12px;
  }

  .quiz-form button {
    padding: 6px;
    font-size: 12px;
  }

  .quiz-form label {
    font-size: 12px;
  }

  .quiz-form h1 {
    font-size: 20px;
  }

  .fee-info {
    font-size: 12px;
  }
}

